import { useMutation, useQuery } from 'react-query';

import queryClient from 'query-client';

import api from 'services/api.service';

export function useList({ page, perPage }) {
  const getList = () => api.get('/v2/a-proxy/proxies', { page, itemsPerPage: perPage })
    .then((data) => ({
      totalItems: data['hydra:totalItems'],
      items: data['hydra:member'],
    }));

  return useQuery(['proxy.list', { page, perPage }], getList);
}

export function useProxyTypes() {
  const getProxyTypes = () => api.get('/v2/a-proxy/proxy-types')
    .then((data) => ({
      totalItems: data['hydra:totalItems'],
      items: data['hydra:member'],
    }));

  return useQuery(['proxy.types'], getProxyTypes);
}

export function useInit() {
  const init = ({ primaryDomain, secondaryDomain, type }) => api.post('/v2/a-proxy/init-proxy', {
    primaryDomain, secondaryDomain, type,
  });

  return useMutation(init);
}

export function useGet(id) {
  const get = () => api.get(`/v2/a-proxy/proxies/${id}`);

  return useQuery(['proxy', { id }], get, {
    enabled: !!id,
  });
}

export function useDelete() {
  const remove = (id) => api.delete(`/v2/a-proxy/proxies/${id}`);

  return useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries('proxy.list');
    },
  });
}
