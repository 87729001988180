import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { mxrightApi } from 'resources/mxright';

import { ArrowBottomIcon, ArrowTopIcon } from 'assets/icons';

import { Divider, IconButton, Chip, CircularProgress } from '@mui/material';

import { Grid, Text } from '@mantine/core';

import BlacklistMatrix from 'pages/Results/components/BlacklistMatrix';

import { getChipValue } from 'pages/MonitorResults';

import './styles.scss';

const BlacklistServiceItem = forwardRef(({ item, mobileWidth, email }, ref) => {
  const itemRef = useRef(null);

  const [records, setRecords] = useState([]);

  const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);

  const { mutate: getServiceDetails, isLoading } = mxrightApi.useGetServiceDetails();

  const isHostDomainDetail = item['@type'] === 'HostDomainDetail';

  const onDescriptionOpenToggle = useCallback((isOpened) => {
    const newIsOpenedState = isOpened !== undefined ? isOpened : !isDescriptionOpened;

    if (records.length || isLoading) {
      setIsDescriptionOpened(newIsOpenedState);

      return;
    }

    getServiceDetails({
      serviceName: item.title,
      email,
    }, {
      onSuccess: (data) => {
        setRecords(data.details['hydra:member']);
      },
    });

    setIsDescriptionOpened(newIsOpenedState);
  }, [isDescriptionOpened, email, getServiceDetails, isLoading, item.title, records.length]);

  useImperativeHandle(ref, () => ({
    openDescription: () => onDescriptionOpenToggle(true),
    scrollToItem: () => {
      if (itemRef.current) {
        const elementPosition = itemRef.current.getBoundingClientRect().top + window.scrollY;
        const offset = 100; // Adjust this value based on your header height

        // Scroll to the element's position minus the offset
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth',
        });
      }
    },
  }));

  return (
    <div
      ref={itemRef}
      className="honeypot-results-item blacklist-services-item"
    >
      <Grid
        justify="space-between"
        columns={9}
        onClick={() => onDescriptionOpenToggle()}
        aria-hidden
        sx={{ cursor: 'pointer' }}
      >
        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text
            className="blacklist-services-item-text"
            truncate
          >
            {isHostDomainDetail ? (item.title || '-') : (item.ipInfo?.ipAddress || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text className="honeypot-results-item-text" truncate>
            {isHostDomainDetail ? item.description : (item.ipInfo?.typeInfo || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text className="honeypot-results-item-text" truncate>
            {isHostDomainDetail ? '-' : (item.ipInfo?.domain || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={2} className="honeypot-results-item-column">
          <Text className="honeypot-results-item-text" truncate>
            {isHostDomainDetail ? '-' : (item.ipInfo?.ipDetail?.country || '-')}
          </Text>
        </Grid.Col>

        <Grid.Col span={1} className="honeypot-results-item-column honeypot-results-item-chip">
          <div
            className="chip-and-arrow-wrapper"
            {...!mobileWidth && { style: { marginRight: '-35px' } }}
          >
            {item.state && (
            <div className="chip-wrapper">
              <Chip
                className="chip blacklist-chip"
                label={getChipValue({ state: item.state, mobileWidth, isClean: true })}
                variant={['Fail', 'Info'].some((value) => value === item.state.value) ? 'filled' : 'outlined'}
                color={item.state.color}
              />
            </div>
            )}

            {!mobileWidth && (
            <IconButton>
              {isDescriptionOpened
                ? <ArrowTopIcon className="arrow" />
                : <ArrowBottomIcon className="arrow" />}
            </IconButton>
            )}
          </div>
        </Grid.Col>
      </Grid>

      {mobileWidth && (
      <div
        className="more-info"
        onClick={() => onDescriptionOpenToggle()}
        aria-hidden
      >
        <p>More info</p>

        <IconButton>
          {isDescriptionOpened
            ? <ArrowTopIcon className="arrow" />
            : <ArrowBottomIcon className="arrow" />}
        </IconButton>
      </div>
      )}

      {isDescriptionOpened && !isLoading && (
      <BlacklistMatrix
        generalState={item.state}
        mobileWidth={mobileWidth}
        items={records}
        withoutTitle
        disablePopover
        email={email}
      />
      )}

      {isDescriptionOpened && isLoading && <CircularProgress sx={{ display: 'block', margin: '100px auto' }} />}

      <Divider sx={{ my: '16px' }} />
    </div>
  );
});

BlacklistServiceItem.propTypes = {
  item: PropTypes.shape().isRequired,
  mobileWidth: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
};

export default React.memo(BlacklistServiceItem);
