import { useMutation, useQuery } from 'react-query';

import api from 'services/api.service';

export function useMenuItemsList(email) {
  const itemsPerPage = 50;
  const url = `/v2/mxright/menu/${email}`;

  const getMenuItemsList = () => api.get(url, {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get(url, {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxright.menu', { email }], getMenuItemsList, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
  });
}

export function useMetersList(email) {
  const itemsPerPage = 50;
  const url = `/v2/mxright/meters/${email}`;

  const getMetersList = () => api.get(url, {
    page: 1,
    itemsPerPage,
  })
    .then(async (data) => {
      const otherPageNumbers = Array.from({
        length: Math.ceil(data['hydra:totalItems'] / itemsPerPage) - 1,
      }, (_, i) => i + 2);

      const otherData = await Promise.all(otherPageNumbers
        .map(async (page) => api.get(url, {
          page,
          itemsPerPage,
        })));

      return [
        ...data['hydra:member'],
        ...otherData.map((check) => check['hydra:member']).flat(1),
      ];
    });

  return useQuery(['mxright.meters', { email }], getMetersList, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
  });
}

export function useCommonData(email) {
  const getCommonData = () => api.get(`/v2/mxright/common-data/${email}`);

  return useQuery(['mxright.commonData', { email }], getCommonData, {
    enabled: !!email,
    keepPreviousData: false,
    cacheTime: 0,
  });
}

export function useGroups() {
  const getGroups = () => api.get('/v2/mxright/groups');

  return useQuery(['monitor.groups'], getGroups);
}

export function useResultsByGroup({ groups, email }, options) {
  const getResultsByGroup = () => Promise.all(groups.map((group) => api.get(`/v2/mxright/items/${group}/${email}`)))
    .then((data) => data.map((groupData) => groupData['hydra:member']))
    .then((data) => data.flat());

  return useQuery(['monitor.resultsByGroup', { groups, email }], getResultsByGroup, {
    enabled: !!groups?.length && !!email,
    ...options,
  });
}

export function useGetServiceDetails() {
  const getServiceDetails = ({
    serviceName,
    email,
  }) => api.get(`/v2/mxright/service-detail/${serviceName}/${email}`);

  return useMutation(getServiceDetails);
}

export function useNetworkInfo(email) {
  const getNetworkInfo = () => api.get(`/v2/mxright/network-info/${email}`);

  return useQuery(['monitor.networkInfo', { email }], getNetworkInfo, {
    enabled: !!email,
  });
}
