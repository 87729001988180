import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan, faCheck, faChevronDown, faChevronUp, faCopy, faFileCertificate, faLayerGroup, faTimer,
} from '@fortawesome/pro-regular-svg-icons';

import AuthorizedLayout from 'layouts/authorized';

import { proxyApi } from 'resources/proxy';

import {
  Alert, AlertTitle, Box, CircularProgress, Divider, Stack, Typography,
} from '@mui/material';

import validateDomain from 'helpers/validateDomain';

import Input from 'components/Input';
import Button from 'components/Button';
import RadioGroup from 'components/RadioGroup';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const DomainMaskingAdd = () => {
  const navigate = useNavigate();

  const [secondaryDomain, setSecondaryDomain] = useState('');
  const [primaryDomain, setPrimaryDomain] = useState('');
  const [proxyType, setProxyType] = useState(null);

  const [proxyId, setProxyId] = useState(null);

  const [errors, setErrors] = useState({
    secondaryDomain: null,
    primaryDomain: null,
    proxyType: null,
  });
  const [generalError, setGeneralError] = useState({
    title: null,
    description: null,
    expanded: false,
  });

  const { data: proxyTypesData } = proxyApi.useProxyTypes();
  const { mutate: initProxy, isLoading: isInitProxyLoading } = proxyApi.useInit();
  const { data: proxy, refetch } = proxyApi.useGet(proxyId);

  const inProgress = proxy && proxy.status.status === 125;
  const isError = proxy && proxy.status.status === 1;
  const isSuccess = proxy && [0, 64].includes(proxy.status.status);

  useEffect(() => {
    const interval = inProgress ? setInterval(refetch, 2000) : null;

    return () => interval && clearInterval(interval);
  }, [inProgress, refetch]);

  useEffect(() => {
    if (!proxyType && proxyTypesData?.items?.length) {
      setProxyType(proxyTypesData.items[0].type);
    }
  }, [proxyType, proxyTypesData]);

  const onChangeInput = useCallback(({ value, setter, errorField }) => {
    setter(value);

    if (errors[errorField]) {
      setErrors((oldErrors) => ({
        ...oldErrors,
        [errorField]: null,
      }));
    }
  }, [errors]);

  const validateForm = useCallback(() => {
    const newErrors = {};

    if (!secondaryDomain) {
      newErrors.secondaryDomain = 'This field is required';
    }

    if (secondaryDomain && !validateDomain(secondaryDomain.toLowerCase())) {
      newErrors.secondaryDomain = 'Incorrect domain format';
    }

    if (!primaryDomain) {
      newErrors.primaryDomain = 'This field is required';
    }

    if (primaryDomain && !validateDomain(primaryDomain.toLowerCase())) {
      newErrors.primaryDomain = 'Incorrect domain format';
    }

    if (!proxyType) {
      newErrors.proxyType = 'This field is required';
    }

    setErrors((oldErrors) => ({
      ...oldErrors,
      ...newErrors,
    }));

    return !Object.keys(newErrors).length;
  }, [secondaryDomain, primaryDomain, proxyType]);

  const onSendDomains = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    setGeneralError({
      title: null,
      description: null,
      expanded: false,
    });

    initProxy({
      secondaryDomain: secondaryDomain.toLowerCase(),
      primaryDomain: primaryDomain.toLowerCase(),
      type: proxyType,
    }, {
      onSuccess: (data) => {
        setProxyId(data.id);
      },
      onError: (error) => {
        setGeneralError({
          title: error.data['hydra:title'] || 'Error',
          description: error.data['hydra:description'] || 'Something went wrong',
        });
      },
    });
  }, [validateForm, initProxy, secondaryDomain, primaryDomain, proxyType]);

  const onCompleteClick = useCallback(() => {
    navigate(routes.domainMasking.path);
  }, [navigate]);

  if (!proxyTypesData) {
    return null;
  }

  return (
    <AuthorizedLayout>
      <div className="domain-masking-add-wrapper">
        <div className="title">
          <p>New Domain Masking Proxy</p>
        </div>

        <div className="domain-masking-add-content">
          <div className="steps-wrapper">
            <div
              className={cn('step', {
                completed: !!proxyId,
              })}
            >
              {proxyId ? <FontAwesomeIcon icon={faCheck} color="#17EDC3" /> : '1'}
            </div>

            <div className="step-line" style={{ height: '480px' }} />

            {!!proxy && (
            <>
              <div
                className={cn('step', {
                  waiting: inProgress,
                  error: isError,
                })}
              >
                {inProgress
                  ? <FontAwesomeIcon icon={faTimer} color="#0091FF" />
                  : (isError && <FontAwesomeIcon icon={faBan} color="#F02B4F" />) || '2'}
              </div>

              <div className="step-line" style={{ height: isError ? '210px' : '410px' }} />
            </>
            )}
          </div>

          <Stack spacing="32px">
            <div className="form-wrapper">
              <div className="input-with-label-wrapper">
                <p>Masking Domain</p>

                <Input
                  className="placeholder-input"
                  placeholder="trycompany.com"
                  value={secondaryDomain}
                  onChange={(value) => onChangeInput({
                    value,
                    setter: setSecondaryDomain,
                    errorField: 'secondaryDomain',
                  })}
                  error={!!errors.secondaryDomain}
                  helperText={errors.secondaryDomain}
                  readOnly={!!proxyId}
                />
              </div>

              <div className="input-with-label-wrapper">
                <p>Primary Domain</p>

                <Input
                  className="placeholder-input"
                  placeholder="company.com"
                  value={primaryDomain}
                  onChange={(value) => onChangeInput({
                    value,
                    setter: setPrimaryDomain,
                    errorField: 'primaryDomain',
                  })}
                  error={!!errors.primaryDomain}
                  helperText={errors.primaryDomain}
                  readOnly={!!proxyId}
                />
              </div>

              {generalError.title && (
              <Alert
                severity="error"
                action={(
                  <div
                    className="more-error-info"
                    onClick={() => {
                      setGeneralError((oldError) => ({
                        ...oldError,
                        expanded: !oldError.expanded,
                      }));
                    }}
                    aria-hidden
                  >
                    <p>More Info</p>

                    {generalError.expanded
                      ? <FontAwesomeIcon icon={faChevronUp} color="rgba(255, 255, 255, 0.70)" fontSize={13} />
                      : <FontAwesomeIcon icon={faChevronDown} color="rgba(255, 255, 255, 0.70)" fontSize={13} />}
                  </div>
                )}
              >
                <AlertTitle sx={{ marginBottom: generalError.expanded ? '16px' : 0 }}>
                  {generalError.title}
                </AlertTitle>

                {generalError.expanded && <p style={{ maxWidth: 400 }}>{generalError.description}</p>}
              </Alert>
              )}

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing="4px" alignItems="center">
                  <RadioGroup
                    items={(proxyTypesData?.items || []).map((item) => ({
                      label: (
                        <Stack direction="row" alignItems="center" gap="8px">
                          <Typography>{item.name}</Typography>
                          {item.type === 201 && <FontAwesomeIcon icon={faFileCertificate} />}
                        </Stack>
                      ),
                      value: item.type,
                    }))}
                    value={proxyType}
                    onChange={(value) => {
                      if (proxyId) {
                        return;
                      }

                      setProxyType(value);

                      if (errors.proxyType) {
                        setErrors((oldErrors) => ({
                          ...oldErrors,
                          proxyType: null,
                        }));
                      }
                    }}
                    disabled={!!proxyId}
                    error={errors.proxyType}
                  />
                </Stack>
              </Stack>

              {!proxyId && (
                <Stack alignSelf="flex-end">
                  <Button
                    className="next-button"
                    onClick={onSendDomains}
                    loading={isInitProxyLoading}
                  >
                    Next
                  </Button>
                </Stack>
              )}
            </div>

            {proxy && (
            <div className="form-wrapper" style={{ gap: '40px' }}>
              {inProgress && <CircularProgress className="loader" />}

              {isSuccess && (
              <>
                <div className="input-with-label-wrapper">
                  <p>Point your Secondary domain A record to this IP address:</p>

                  <Input
                    className="placeholder-input"
                    value={proxy.ip}
                    rightIcon={(
                      <FontAwesomeIcon
                        icon={faCopy}
                        color="rgba(255, 255, 255, 0.56)"
                        width={24}
                        fontSize={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard.writeText(proxy.ip);
                          uiNotificationService.showSuccess('Copied');
                        }}
                      />
                  )}
                  />
                </div>

                <div className="what-to-do-next-wrapper">
                  <p>What to do next:</p>

                  <p>
                    Add DNS records to connect your domain to our service.
                    Log in to your domain registrar or DNS hosting provider, find the DNS settings,
                    and create an A record pointing your domain  to provided IP. If needed, update
                    existing records or add additional configurations as required. Changes may take
                    some time to propagate across the internet.
                  </p>

                  {false && (
                  <div>
                    {[
                      {
                        title: 'Action title number one',
                        // eslint-disable-next-line max-len
                        description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
                      },
                      {
                        title: 'Action title number two',
                        // eslint-disable-next-line max-len
                        description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
                      },
                      {
                        title: 'Action title number three',
                        // eslint-disable-next-line max-len
                        description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
                      },
                    ].map((item, index, array) => (
                      <Stack key={item.title}>
                        <Stack direction="row" spacing="24px">
                          <Box className="icon-wrapper">
                            <FontAwesomeIcon icon={faLayerGroup} color="#17EDC3" />
                          </Box>

                          <Stack className="text-wrapper">
                            <Typography>{item.title}</Typography>
                            <Typography>{item.description}</Typography>
                          </Stack>
                        </Stack>

                        {index < array.length - 1 && <Divider sx={{ my: '16px' }} />}
                      </Stack>
                    ))}
                  </div>
                  )}
                </div>
              </>
              )}

              {isError && (
              <div className="what-to-do-next-wrapper">
                <p>Error:</p>

                <p>
                  {proxy.status.message}
                </p>
              </div>
              )}

              {!inProgress && (
              <Button
                className="complete-button"
                onClick={onCompleteClick}
              >
                Complete
              </Button>
              )}
            </div>
            )}
          </Stack>
        </div>
      </div>
    </AuthorizedLayout>
  );
};

export default DomainMaskingAdd;
