import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, Radio, RadioGroup as MUIRadioGroup, FormControlLabel, FormHelperText } from '@mui/material';

import './styles.scss';

const RadioGroup = ({ value, onChange, items, isRow, name, error, disabled }) => (
  <FormControl error={!!error} disabled={disabled}>
    <MUIRadioGroup
      row={isRow}
      aria-labelledby="demo-controlled-radio-buttons-group"
      value={value}
      onChange={(e) => onChange(e.target.value, name)}
    >
      {items.map((item) => (
        <FormControlLabel
          key={item.value}
          value={item.value}
          control={<Radio />}
          label={item.label}
          disabled={item.disabled}
        />
      ))}

      {error && <FormHelperText>{error}</FormHelperText>}
    </MUIRadioGroup>
  </FormControl>
);

RadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.string,
    disabled: PropTypes.bool,
  })).isRequired,
  isRow: PropTypes.bool,
  name: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
  isRow: false,
  name: null,
  error: null,
  disabled: false,
};

export default React.memo(RadioGroup);
