import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { faGridDividers } from '@fortawesome/sharp-light-svg-icons';
import { faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons';

import { Tooltip } from '@mui/material';

import { Center, Grid, Group, Stack, Text } from '@mantine/core';

import { getGeneralState } from 'pages/Results';

import './styles.scss';

import BlacklistMatrixItem from './components/BlacklistMatrixItem';

const matrixBackgroundColorByTier = {
  1: '#3A3F60',
  2: '#34384F',
  3: '#2E3243',
};

const matrixFailedBackgroundColorByTier = {
  1: 'rgba(224, 27, 73, 0.50)',
  2: 'rgba(224, 27, 73, 0.50)',
  3: 'rgb(147 147 147 / 50%)',
};

const BlacklistMatrix = ({
  mobileWidth,
  items,
  withoutTitle,
  scrollToServiceItem,
  disablePopover,
  email,
}) => {
  const tiers = items.reduce((acc, item) => {
    if (item.tier && !acc.includes(item.tier)) {
      acc.push(item.tier);
    }

    return acc;
  }, []).sort((a, b) => a - b);

  return (
    <div className={cn(
      'monitor-result-group-section',
      'pagebreak',
      {
        'no-paddings': withoutTitle,
      },
    )}
    >
      {!withoutTitle && (
      <div className="group-section-title">
        <div className="align-items-center">
          <div className={cn('group-status-mark', {
            default: true,
          })}
          >
            <FontAwesomeIcon icon={faGridDividers} />
          </div>

          <div className="group-section-text">
            <p>Email Infrastructure Reputation Dashboard</p>
          </div>
        </div>

        {/* <div className="chip-and-arrow-wrapper">
          {generalState && (
          <div className="chip-wrapper">
            <Chip
              className="chip"
              label={getChipValue({ state: generalState, mobileWidth, isClean: true })}
              variant={generalState.value === 'Fail' ? 'filled' : 'outlined'}
              color={generalState.color}
            />
          </div>
          )}
        </div> */}
      </div>
      )}

      <div
        className={cn('blacklist-matrix-wrapper', {
          'no-paddings': withoutTitle,
        })}
      >
        <Stack spacing={2}>
          {tiers.map((tier, tierIndex, arr) => {
            const rows = items
              .filter((item) => item.tier === tier && item.state?.value !== 'Skipped')
              .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

            const firstTierPerRow = rows.length; // Show all items in one row for the first tier
            const otherTiersPerRow = 11;

            const itemsPerRow = [1, 2].includes(tier) ? firstTierPerRow : otherTiersPerRow;

            const totalItemsInFullRows = Math.ceil(rows.length / itemsPerRow) * itemsPerRow;
            const placeholdersCount = totalItemsInFullRows - rows.length;

            const placeholders = Array.from({ length: placeholdersCount }).map((_, i) => ({
              '@id': `placeholder-${i}`,
              title: '',
              tier,
              isPlaceholder: true,
            }));

            const rowsWithPlaceholders = [...rows, ...placeholders];
            const rowsCount = Math.ceil(rowsWithPlaceholders.length / itemsPerRow);

            const isSmall = ![1, 2].includes(tier) && rowsCount <= 1;

            let tierIcon = null;

            const tierGeneralState = getGeneralState(rows);

            if (!tierGeneralState) {
              return;
            }

            if (tierGeneralState.value === 'Fail') {
              tierIcon = (
                <Tooltip title="Listed" placement="top">
                  <FontAwesomeIcon icon={faBan} color="#F02B4F" fontSize={14} />
                </Tooltip>
              );
            } else if (tierGeneralState.value === 'Success') {
              tierIcon = (
                <Tooltip title="Clean" placement="top">
                  <FontAwesomeIcon icon={faCircleCheck} color="rgba(23, 237, 195, 1)" fontSize={14} />
                </Tooltip>
              );
            } else if (tierGeneralState.value === 'Info') {
              tierIcon = (
                <Tooltip title="Clean" placement="top">
                  <FontAwesomeIcon icon={faCircleInfo} color="rgb(147 147 147 / 90%)" fontSize={14} />
                </Tooltip>
              );
            }

            return (
              <Group key={tier} spacing={2} align="stretch">
                {/* Fixed width Tier label */}
                <Center
                  w={36}
                  sx={{
                    backgroundColor: matrixBackgroundColorByTier[tier],
                    ...(tierIndex === 0 && { borderTopLeftRadius: 10 }),
                    ...(tierIndex === arr.length - 1 && { borderBottomLeftRadius: 10 }),
                  }}
                >
                  <Group
                    position="center"
                    spacing={12}
                  >
                    {tierIcon}

                    <Text
                      className="tier-label"
                      style={{
                        ...(isSmall && { fontSize: 9 }),
                      }}
                    >
                      TIER {tier}
                    </Text>
                  </Group>
                </Center>

                {/* Main content grid */}
                <Grid gutter={2} sx={{ flex: 1 }}>
                  <Grid.Col>
                    <Grid gutter={2} columns={itemsPerRow}>
                      {rowsWithPlaceholders.map((item, tierRowsIndex, rowArr) => {
                        const failed = ['Fail', 'Info'].includes(item.state?.value);

                        return (
                          <Grid.Col
                            span={1}
                            key={item['@id']}
                          >
                            <BlacklistMatrixItem
                              item={item}
                              backgroundColor={failed
                                ? matrixFailedBackgroundColorByTier[tier]
                                : matrixBackgroundColorByTier[tier]}
                              failed={failed}
                              firstTierPerRow={firstTierPerRow}
                              tierIndex={tierIndex}
                              tierRowsIndex={tierRowsIndex}
                              tiersLength={arr.length}
                              tierRowsLength={rowArr.length}
                              scrollToServiceItem={scrollToServiceItem}
                              disablePopover={disablePopover}
                              email={email}
                            />
                          </Grid.Col>
                        );
                      })}
                    </Grid>
                  </Grid.Col>
                </Grid>
              </Group>
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

BlacklistMatrix.propTypes = {
  mobileWidth: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  withoutTitle: PropTypes.bool,
  scrollToServiceItem: PropTypes.func,
  disablePopover: PropTypes.bool,
  email: PropTypes.string.isRequired,
};

BlacklistMatrix.defaultProps = {
  withoutTitle: false,
  scrollToServiceItem: null,
  disablePopover: false,
};

export default React.memo(BlacklistMatrix);
