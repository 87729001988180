import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { TrashIcon } from 'assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMasksTheater, faXmark } from '@fortawesome/pro-regular-svg-icons';

import { proxyApi } from 'resources/proxy';

import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';

import DeleteModal from 'components/DeleteModal';

import uiNotificationService from 'services/uiNotificatuion.service';

import './styles.scss';

const getChipColorByStatusCode = (statusCode) => {
  switch (statusCode) {
    case 125:
      return 'info';
    case 1:
      return 'error';
    case 0:
      return 'success';
    default:
      return 'default';
  }
};

const ProxyItem = ({ item }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const {
    mutate: deleteProxy,
    isLoading: isDeleteProxyLoading,
  } = proxyApi.useDelete();

  const inProgress = item.status.status === 125;
  const isError = item.status.status === 1;
  const isSuccess = item.status.status === 0;

  const isSeoSafe = item.type === 201;

  const chipColor = getChipColorByStatusCode(item.status.status);

  const onDeleteClick = useCallback((e) => {
    e.stopPropagation();

    setIsDeleteModalVisible(true);
  }, []);

  const onDelete = useCallback(() => {
    deleteProxy(item.id, {
      onSuccess: () => {
        uiNotificationService.showSuccess('History item has been deleted!');

        setIsDeleteModalVisible(false);
      },
    });
  }, [deleteProxy, item.id]);

  return (
    <div className="proxy-item-row-container" aria-hidden>
      <DeleteModal
        title="Are you sure you want to delete this proxy?"
        open={isDeleteModalVisible}
        onClose={(e) => { e.stopPropagation(); setIsDeleteModalVisible(false); }}
        onDelete={(e) => { e.stopPropagation(); onDelete(); }}
        isDeleteLoading={isDeleteProxyLoading}
      />

      <div className="item-row">
        <div className="secondary-domain">
          <div className={cn('icon-wrapper', {
            completed: isSuccess,
            waiting: inProgress,
            error: isError,
          })}
          >
            <FontAwesomeIcon icon={faMasksTheater} />
          </div>

          <p>{item.secondaryDomain}</p>
        </div>

        <p>{item.primaryDomain}</p>

        <p>{item.ip || '-'}</p>

        <div>
          {isSeoSafe
            ? <FontAwesomeIcon icon={faCheck} color="#17EDC3" />
            : <FontAwesomeIcon icon={faXmark} color="rgba(255, 255, 255, 0.3)" />}
        </div>

        <p>{item.typeDefinition}</p>

        <div className="status-column">
          <Chip
            label={item.status.statusString}
            variant="outlined"
            color={chipColor}
          />

          <div className="item-actions">
            <Tooltip onClick={onDeleteClick} title="Delete" placement="top" disabled={isDeleteProxyLoading}>
              <div className="icon-wrapper">
                <TrashIcon />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

ProxyItem.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default ProxyItem;
